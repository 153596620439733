import React from 'react';
import Layout from '../components/layout';
import MonthComponent from '../components/MonthComponent';

const Month = ({pageContext}) => {
    const pageTitle = `${pageContext.kernel_name} in ${pageContext.yearDate}`;
    return (
        <Layout menuList={[pageContext.lang.name, pageContext.yearDate]} pageTitle={pageTitle} pageLang={pageContext.lang}>
            <MonthComponent 
            monthContent={pageContext.content}
            pageTitle={pageTitle}
            language={pageContext.lang.name}
            yearDate={pageContext.yearDate}/>
        </Layout>
    );
};

export default Month;
