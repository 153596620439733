import React from 'react';
import { Link } from 'gatsby';

const yearBut = {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #ddd',
    borderRadius: '5px',
    margin: '5px',
    padding: '5px',
    userSelect: 'none',
    color: '#000'
}

const monthInText_arr = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
]

const MonthComponent = ({monthContent, pageTitle, language, yearDate}) => {
  return (
    <div>
        <div className="col-md-12">
            <h1 style={{marginTop: '30px'}}>{pageTitle}</h1>
        </div>
        <div className="row">
            {monthContent.map(elem => (
                <div key={Date.now()+Math.random()} className="col-md-3 col-sm-6">
                    <Link style={{textDecoration: 'none'}} to={'/'+language+'/'+yearDate+'/'+elem+'/'}>
                        <div style={yearBut}>{monthInText_arr[elem-1]+' '+yearDate}</div>
                    </Link>
                </div>
            ))}
        </div>
    </div>
  )
}

export default MonthComponent;